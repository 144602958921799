// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import 'jquery';
import 'bootstrap/dist/js/bootstrap.min';
// import '../stylesheets/application.scss';
// import * as ActiveStorage from "@rails/activestorage"
import 'summernote/dist/summernote.min'
// import "channels"

Rails.start()
// ActiveStorage.start()

$('.hall-show').ready(
  function() {
    var hash = window.location.hash;
    hash && $('ul.nav a[href="' + hash + '"]').tab('show');
  }
)

$('.js-search-select').ready(
  function () {
    $('.js-search-select').on('change', function () {
      $('.js-search-input').click()
    })
  }
)

'use-strict';

window.setSlider = function () {
    // Next(Prev)ボタンの取得
    const next = document.querySelector(".next");
    const prev = document.querySelector(".prev");

    // liタグのwidthを取得
    const sliderwidth = $(".sliderlist__item");
    let width = sliderwidth.width();

    // slider(ul要素、li要素一覧)の取得
    const sliderlist = document.querySelector(".sliderlist");
    const sliderlist_item = document.querySelectorAll(".sliderlist__item")

    // カウンターの設定
    let counter = 0;

    // イベントリスナー (next)
    next.addEventListener("click", function(){
        if(counter == sliderlist_item.length - 1) return; //ボタン連打対策
        prev.style.display = "block";
        sliderlist.style.transition = ".3s";
        counter ++;
        sliderlist.style.transform = "translateX("+ (- width * counter) + "px)";

        sliderlist.addEventListener("transitionend", function(){
            if(counter == sliderlist_item.length - 1){
                sliderlist.style.transition = "none";
                next.style.display = "none";
            }
        })
    });

    // イベントリスナー (prev)
    prev.addEventListener("click", function(){
        if(counter == sliderlist_item.length - sliderlist_item.length) return; //ボタン連打対策
        next.style.display = "block";
        sliderlist.style.transition = ".3s";
        counter --;
        sliderlist.style.transform = "translateX("+ (- width * counter) + "px)";

        sliderlist.addEventListener("transitionend", function(){
            if(counter == sliderlist_item.length - sliderlist_item.length){
                sliderlist.style.transition = "none";
                prev.style.display = "none";
            }
        })
    });
}
$('.sliderlist__item').ready(function(){
    if ($('.sliderlist__item').length > 0) setSlider()
});

var setModalOpen = function (buttonOpen, modal) {
  buttonOpen.addEventListener('click', function () {
    modal.style.display = 'block';
  });
}

var setModalClose = function (buttonClose, modal) {
  buttonClose.addEventListener('click', function () {
    modal.style.display = 'none';
  });
}

$(document).ready(function() {
  // モーダル
  const modalLine = document.getElementById('easyModalLine');
  const modalTwitter = document.getElementById('easyModalTwitter');

  const buttonOpenLine = document.getElementById('modalOpenLine');
  const buttonCloseLine = document.getElementsByClassName('modalCloseLine')[0];

  const buttonOpenTwitter = document.getElementById('modalOpenTwitter');
  const buttonCloseTwitter = document.getElementsByClassName('modalCloseTwitter')[0];

  setModalOpen(buttonOpenLine, modalLine);
  setModalOpen(buttonOpenTwitter, modalTwitter);
  setModalClose(buttonCloseLine, modalLine);
  setModalClose(buttonCloseTwitter, modalTwitter);

  addEventListener('click', function (e) {
    if (e.target == modalLine) {
      modalLine.style.display = 'none';
    } else if (e.target == modalTwitter) {
      modalTwitter.style.display = 'none';
    }
  });
});